<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    fill="currentColor"
    stroke-width="0"
    viewBox="0 0 24 24"
    height="1em"
    width="1em"
  >
    <path d="M16.707 7.404c-.189-.188-.448-.283-.707-.283s-.518.095-.707.283L13 9.697V3a1 1 0 00-2 0v6.697L8.707 7.404a.997.997 0 00-1.414 0 1 1 0 000 1.414L12 13.5l4.709-4.684a1 1 0 00-.002-1.412zM20.987 16a.98.98 0 00-.039-.316l-2-6A.998.998 0 0018 9h-.219c-.094.188-.21.368-.367.525L15.932 11h1.348l1.667 5H5.054l1.667-5h1.348L6.586 9.525A1.96 1.96 0 016.219 9H6a.998.998 0 00-.948.684l-2 6a.98.98 0 00-.039.316C3 16 3 21 3 21a1 1 0 001 1h16a1 1 0 001-1s0-5-.013-5z"></path>
  </svg>
</template>